<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="60%"
    @close="close"
  >
    <el-form
      ref="formRef"
      :inline="true"
      label-width="140px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="父节点" prop="parentName">
        <el-select
          v-model="form.parentName"
          ref="configSelect"
          placeholder="请选择父节点"
        >
          <el-option
            :label="form.name"
            style="height: auto; padding: 0"
            :value="form.menuId"
          >
            <el-tree
              ref="treeRef"
              :data="treeData"
              default-expand-all
              :props="defaultProps"
              @node-click="handleNodeClick"
            />
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="菜单类型" prop="type">
        <el-radio-group v-model="form.type">
          <el-radio :label="0">菜单</el-radio>
          <el-radio :label="1">按钮</el-radio>
        </el-radio-group>
      </el-form-item>

      <template v-if="form.type == 0">
        <el-form-item label="菜单名称" prop="menuName">
          <el-input v-model="form.menuName" />
        </el-form-item>
        <el-form-item label="显示顺序" prop="sortNum">
          <el-input v-model="form.sortNum" />
        </el-form-item>

        <el-form-item label="路由地址" prop="path">
          <el-input v-model="form.path" />
        </el-form-item>
        <el-form-item label="vue文件路径" prop="component">
          <el-input v-model="form.component" />
        </el-form-item>
        <el-form-item label="重定向" prop="redirect">
          <el-input v-model="form.redirect" />
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio :label="0">正常</el-radio>
            <el-radio :label="1">停用</el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- meta 相关 -->
        <el-form-item label="标题" prop="meta.title">
          <el-input v-model="form.meta.title" />
        </el-form-item>
        <el-form-item label="图标">
          <el-popover
            popper-class="icon-selector-popper"
            trigger="hover"
            :width="292"
          >
            <template #reference>
              <el-input v-model="form.icon" />
            </template>
            <vab-icon-selector @handle-icon="handleIcon" />
          </el-popover>
        </el-form-item>
        <el-form-item label="badge">
          <el-input v-model="form.meta.badge" />
        </el-form-item>
        <el-form-item label="dot">
          <el-switch v-model="form.meta.dot" />
        </el-form-item>
        <el-form-item label="隐藏">
          <el-switch v-model="form.meta.hidden" />
        </el-form-item>
        <el-form-item label="始终显示当前节点">
          <el-switch v-model="form.meta.levelHidden" />
        </el-form-item>
        <el-form-item label="固定">
          <el-switch v-model="form.meta.noClosable" />
        </el-form-item>
        <el-form-item label="无缓存">
          <el-switch v-model="form.meta.noKeepAlive" />
        </el-form-item>
        <el-form-item label="不显示当前标签页">
          <el-switch v-model="form.meta.tabHidden" />
        </el-form-item>
      </template>
      <!-- 设置页面权限 -->
      <template v-else>
        <el-form-item label="菜单名称" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item label="显示顺序" prop="sortNum">
          <el-input v-model="form.sortNum" />
        </el-form-item>
        <el-form-item label="vue文件路径" prop="component">
          <el-input v-model="form.component" />
        </el-form-item>
        <el-form-item label="重定向" prop="redirect">
          <el-input v-model="form.redirect" />
        </el-form-item>

        <el-form-item label="权限标识" prop="test">
          <el-select v-model="form.auth">
            <el-option label="测试" value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio :label="0">正常</el-radio>
            <el-radio :label="1">停用</el-radio>
          </el-radio-group>
        </el-form-item>
      </template>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    reactive,
    toRefs,
    onMounted,
  } from 'vue'
  import VabIconSelector from '@/extra/VabIconSelector'
  import { doEdit } from '@/api/menuManagement'
  import { getList } from '@/api/router'

  export default defineComponent({
    name: 'MenuManagementEdit',
    components: { VabIconSelector },
    emits: ['fetch-data'],
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        formRef: null,
        treeData: [],
        defaultProps: {
          children: 'children',
          label: 'name',
        },

        form: {
          type: 0,
          menuName: '',
          menuId: '',
          meta: {
            title: '',
            icon: '',
            badge: '',
            dot: false,
            hidden: false,
            levelHidden: false,
            isCustomSvg: false,
            noClosable: false,
            noKeepAlive: false,
            tabHidden: false,
          },
        },
        rules: {
          parentName: [
            { required: true, trigger: 'blur', message: '请输入父级id' },
          ],
          menuName: [
            { required: true, trigger: 'blur', message: '请输入菜单名称' },
          ],
          type: [{ required: true, trigger: 'change', message: '请选择类型' }],
          path: [{ required: true, trigger: 'blur', message: '请输入path' }],
          component: [
            { required: true, trigger: 'blur', message: '请输入component' },
          ],
          'meta.title': [
            { required: true, trigger: 'blur', message: '请输入标题' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      })

      const handleIcon = (item) => {
        state.form.meta.icon = item
      }

      const handleNodeClick = (item) => {
        console.log(item)
        proxy.form.menuId = item.menuId
        proxy.form.menuParentId = item.menuParentId
        proxy.form.parentName = item.name
        proxy.$refs.configSelect.blur()
      }

      const getListMenu = async () => {
        const { data } = await getList()
        console.log(data)
        let originDept = {
          children: [],
          component: 'Layout',
          menuId: 0,
          name: '顶级菜单',
          path: '/',
          menuParentId: 0,
        }
        data.unshift(originDept)
        proxy.treeData = data
      }
      onMounted(() => {
        proxy.getListMenu()
      })
      const showEdit = (row) => {
        if (!row) {
          state.title = '添加'
        } else {
          state.title = '编辑'
          state.form = Object.assign({}, row)
        }
        state.dialogFormVisible = true
      }
      const close = () => {
        state['formRef'].resetFields()
        state.form = {
          meta: {
            icon: '',
          },
        }
        state.dialogFormVisible = false
      }
      const save = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            const { msg } = await doEdit(state.form)
            proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
            emit('fetch-data')
            close()
          }
        })
      }

      return {
        ...toRefs(state),
        handleIcon,
        showEdit,
        close,
        save,
        getListMenu,
        handleNodeClick,
      }
    },
  })
</script>
